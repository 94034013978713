import Vue from 'vue'
import App from './App.vue'
import { Button, Select, Radio, Input, RadioGroup } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 引入样式
Vue.use(Button)
Vue.use(Select)
Vue.use(Radio)
Vue.use(Input)
Vue.use(RadioGroup)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
