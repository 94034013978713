<template>
  <div class="container">
    <h2 class="h2_title">ToDoList</h2>

    <!-- 输入框 -->
    <div class="input_box_wrap">
      <el-input
        class="input_box"
        v-model="inputData"
        placeholder="请输入备忘内容"
        @keyup.enter="handleKeyUp"
      ></el-input>
      <el-button class="btn_add" @click="handleAdd">添加</el-button>
    </div>

    <!-- 待办 列表 -->
    <div class="todo_list" v-if="listData.length">
      <div class="todo_item" v-for="item in listData" :key="item">
        <el-radio
          v-model="radio"
          :label="item"
          class="radio_box"
        ></el-radio>
        <i class="el-icon-close" style="font-size: 1vw;" @click="deletData(item)"></i>
      </div>
    </div>
    <div v-else>
      <p class="no_data">暂无待办事项！</p>
    </div>

    <!-- <el-button :plain="true" @click="msgClick">1</el-button> -->
  </div>
</template>

<script>
import { Message } from 'element-ui'
export default {
  name: 'indexPage',
  data() {
    return {
      radio: '',
      inputData: '', //输入框的数据
      listData: JSON.parse(localStorage.getItem('listData')) || []
    }
  },

  mounted() {
    document.addEventListener('keyup', this.handleKeyUp)
    console.log(JSON.parse(localStorage.getItem('listData')))
  },

  methods: {
    handleKeyUp(event) {
      if (event.key === 'Enter') {
        if (this.inputData == '') {
          return Message({
            message: '不能为空哦！',
            type: 'warning'
          })
        }
        //输入数据去重
        if (this.listData.includes(this.inputData)) {
          Message({
            message: '数据重复！',
            type: 'warning'
          })
          return
        } else {
          this.listData.push(this.inputData)
          localStorage.setItem('listData', JSON.stringify(this.listData))
          this.inputData = ''
          Message({
            message: '添加成功！',
            center: true,
            type: 'success'
          })
        }
      }
    },

    //添加
    handleAdd() {
      if (this.inputData == '') {
        return Message({
          message: '不能为空哦！',
          type: 'warning'
        })
      }
      //输入数据去重
      if (this.listData.includes(this.inputData)) {
        Message({
          message: '数据重复！',
          type: 'warning'
        })
        return
      } else {
        this.listData.push(this.inputData)
        localStorage.setItem('listData', JSON.stringify(this.listData))
        this.inputData = ''
        Message({
          message: '添加成功！',
          center: true,
          type: 'success'
        })
      }
    },

    msgClick(vlaue) {
      Message({
        message: vlaue,
        center: true
      })
    },

    deletData(value) {
      this.listData = this.listData.filter(item => value != item)
      localStorage.setItem('listData', JSON.stringify(this.listData))
    },
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyUp)
  }
}
</script>

<style scoped>
.container {
  text-align: center;
  font-size: 2rem;
}
.h2_title {
  text-align: center;
  margin: 4vw auto;
}
.todo_list {
  width: 40vw;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.radio_box {
  height: 4vw;
  width: 40vw;
  margin: 0.4vw 0;
  padding: 1vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 1vw;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 10px rgba(0, 0, 0, 0.1);
}

::v-deep .el-radio__label {
  margin-right: 1vw;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal; /* 换行 */
  text-align: left;
  word-wrap: break-word; /* 允许在长单词或URL中间进行换行 */
  word-break: break-all; /* 允许在任意字符间断开（例如CJK字符） */
  max-width: 100%; /* 设置最大宽度，确保内容不会超出容器宽度 */
}

.radio_box:hover {
  transform: translate(-0.03vw, -0.03vw);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2),
    inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.input_box {
  box-sizing: border-box;
  width: 40vw;
  margin: 2vw 0;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 10px rgba(0, 0, 0, 0.1);
}
.no_data {
  color: #ccc;
  font-size: 1.5rem;
}
.btn_add {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 10px rgba(0, 0, 0, 0.1);
}
.btn_add:hover {
  transform: translate(-0.02vw, -0.02vw);
}
.input_box_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.todo_item{
  display: flex;
  align-items: center;
  position: relative;
}
.el-icon-close{
  position: absolute;
  right: 0.8vw;
  cursor: pointer;
}
</style>
