<template>
  <div id="app">
     <indexPage></indexPage>
  </div>
</template>

<script>
import indexPage from './pages/index/index'

export default {
  name: 'app',
  components: {
    indexPage
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  }
  body{
    background-color: #f3f3f3;
  }
</style>
